/*FONTS*/

.dm-sans {
    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
}

.castoro {
    font-family: "Castoro", serif;
    font-optical-sizing: auto;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Casual';
    src: local('Casual'),
        url('../fonts/Casual-Regular.ttf') format('truetype');
}

.casual {
    font-family: 'Casual', sans-serif;
}

/*COLORS*/

p.primary,
input.primary,
.input.primary,
.primary.inverted p,
span.primary {
    color: var(--primary);
}

p.black,
input.black,
.input.black,
span.black {
    color: var(--black);
}

p.white,
input.white,
.input.white,
span.white {
    color: var(--white);
}

p.green,
span.green {
    color: var(--green);
}

p.faded-grey,
span.faded-grey {
    color: var(--faded-grey);
}

p.bolded-grey,
span.bolded-grey {
    color: var(--bolded-grey);
}

p.light-grey,
span.light-grey {
    color: var(--light-grey);
}

p.pink {
    color: var(--pink);
}

/*WEIGHTS*/

.bold {
    font-weight: bold;
}

.thin {
    font-weight: 300;
}

.semi-bold {
    font-weight: 500;
}

.font-12 {
    font-size: 12rem;
}

.font-14 {
    font-size: 14rem;
}

.font-16 {
    font-size: 16rem;
}

.font-18 {
    font-size: 18rem;
}

.font-20 {
    font-size: 20rem;
}

.font-24 {
    font-size: 24rem;
}

.font-28 {
    font-size: 28rem;
}

.font-32 {
    font-size: 32rem;
}

.font-36 {
    font-size: 36rem;
}

.font-40 {
    font-size: 40rem;
}

.font-48 {
    font-size: 48rem;
}

.font-64 {
    font-size: 64rem;
}

/*ALIGNMENT*/

p.align-start {
    text-align: start;
}

p.align-center {
    text-align: center;
}

p.align-end {
    text-align: end;
}

/*OVERFLOW*/

.nowrap, .nowrap p {
    white-space: nowrap;
}

.clip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    width: 100%;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

p.wrap {
    white-space: wrap;
}

/*TEXT DECORATION*/

.uppercase {
    text-transform: uppercase;
}

.underlined {
    text-decoration: underline;
}

/*INTERACTIONS*/

.hover-primary:hover {
    color: var(--primary);
}

.bold-hover {
    white-space: nowrap;
}

.bold-hover::after {
    display: block;
    content: attr(name);
    font-weight: bold;
    height: 1rem;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
}

.bold-hover:hover {
    cursor: pointer;
    font-weight: bold;
    transition: all .2s;
}

.underline-hover:hover {
    text-decoration: underline;
}

.selected-category p {
    color: var(--primary);
    font-weight: bold;
}

/*PRESETS*/