/*FRAMES*/

.frame {
    display: flex;
}

.inline-block {
    display: inline-block;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.row-reverse {
    flex-direction: row-reverse;
}

.column-reverse {
    flex-direction: column-reverse;
}

.wrap {
    flex-wrap: wrap;
}

.wrap-reverse {
    flex-wrap: wrap-reverse;
}

.center {
    justify-content: center;
    align-items: center;
    align-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

.align-start {
    align-items: flex-start;
    align-content: flex-start;
}

.align-end {
    align-items: flex-end;
    align-content: flex-end;
}

.align-center {
    align-items: center;
    align-content: center;
}

.align-baseline {
    align-items: baseline;
    align-content: baseline;
}

.align-stretch {
    align-items: stretch;
    align-content: stretch;
}

.align-self-start {
    align-self: flex-start;
}

.align-self-end {
    align-self: flex-end;
}

.align-self-center {
    align-self: center;
}

.stretch {
    align-self: stretch;
}

.flex-100 {
    flex: 1 0 0;
}

.flex-200 {
    flex: 2 0 0;
}

.flex-010 {
    flex: 0 1 0;
}

.display-none {
    display: none;
}

/*SIZE*/

.width-100 {
    width: 100%;
}

.height-100 {
    height: 100%;
}

.width-max {
    max-width: 100vw;
}

.height-max {
    max-height: 100vh;
}

/*GAP*/

.gap-0 {
    gap: 0;
}

.gap-2 {
    gap: 2rem;
}

.gap-4 {
    gap: 4rem;
}

.gap-6 {
    gap: 6rem;
}

.gap-8 {
    gap: 8rem;
}

.gap-10 {
    gap: 10rem;
}

.gap-12 {
    gap: 12rem;
}

.gap-16 {
    gap: 16rem;
}

.gap-18 {
    gap: 18rem;
}

.gap-20 {
    gap: 20rem;
}

.gap-auto {
    gap: auto;
}

/*PADDING*/

.padding-0 {
    padding: 0rem;
}

.padding-4 {
    padding: 4rem;
}

.padding-8 {
    padding: 8rem;
}

.padding-12 {
    padding: 12rem;
}

.padding-16 {
    padding: 16rem;
}

.padding-18 {
    padding: 18rem;
}

.padding-20 {
    padding: 20rem;
}

.padding-24 {
    padding: 24rem;
}

.vert-padding-4 {
    padding: 4rem 0;
}

.vert-padding-8 {
    padding: 8rem 0;
}

.vert-padding-12 {
    padding: 12rem 0;
}

.vert-padding-16 {
    padding: 16rem 0;
}

.vert-padding-18 {
    padding: 18rem 0;
}

.vert-padding-20 {
    padding: 20rem 0;
}

.vert-padding-24 {
    padding: 24rem 0;
}

.horiz-padding-2 {
    padding: 0 2rem;
}

.horiz-padding-4 {
    padding: 0 4rem;
}

.horiz-padding-8 {
    padding: 0 8rem;
}

.horiz-padding-12 {
    padding: 0 12rem;
}

.horiz-padding-16 {
    padding: 0 16rem;
}

.horiz-padding-18 {
    padding: 0 18rem;
}

.horiz-padding-20 {
    padding: 0 20rem;
}

.horiz-padding-24 {
    padding: 0 24rem;
}

.padding-4.button {
    padding: 4rem 8rem;
}

.padding-8.button {
    padding: 8rem 16rem;
}

.padding-12.button {
    padding: 12rem 24rem;
}

.padding-16.button {
    padding: 16rem 32rem;
}

.padding-18.button {
    padding: 18rem 36rem;
}

.padding-20.button {
    padding: 20rem 40rem;
}

.padding-24.button {
    padding: 24rem 48rem;
}

.padding-32.button {
    padding: 36rem 64rem;
}

/*BORDER*/

.border-1,
.border-2,
.border-4,
.border-8 {
    border-style: solid;
}

.border-1 {
    border-width: 1rem;
}

.border-2 {
    border-width: 2rem;
}

.border-4 {
    border-width: 4rem;
}

.border-8 {
    border-width: 8rem;
}

.radius-4 {
    border-radius: 4rem;
}

.radius-8 {
    border-radius: 8rem;
}

.radius-12 {
    border-radius: 12rem;
}

.radius-24 {
    border-radius: 24rem;
}

.ellipse {
    border-radius: 50%;
}

/*POSITION*/

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

/*OVERFLOW*/

.overflow-hidden {
    overflow: hidden;
}

.overflow-scroll {
    overflow: scroll;
}

/*COLORS*/

div.transparent,
a.transparent {
    background-color: #0000;
    border-color: #0000;
}

div.primary,
a.primary {
    background-color: var(--primary);
    border-color: var(--primary);
}

div.primary-light,
a.primary-light {
    background-color: var(--primary-light);
    border-color: var(--primary-light);
}

div.black,
a.black {
    background-color: var(--black);
    border-color: var(--black);
}

div.primary,
div.black,
a.primary,
a.black {
    color: var(--white);
}

div.true-white,
a.true-white {
    background-color: white;
    border-color: white;
}

div.white,
a.white {
    background-color: var(--white);
    border-color: var(--white);
}

div.offwhite,
a.offwhite {
    background-color: var(--offwhite);
    border-color: var(--offwhite);
}

div.button-white {
    background-color: var(--button-white);
    border-color: var(--button-white);
}

div.white,
div.off-white,
div.primary-light,
div.button-white {
    color: var(--black);
}

div.primary.inverted {
    background-color: var(--offwhite);
    border: 2.5rem solid var(--primary);

    color: var(--primary);
}

div.primary-light.inverted {
    background-color: #0000;
    border-color: var(--primary-light);

    color: var(--primary);
}

div.black.inverted {
    background-color: #0000;
    border-color: var(--black);

    color: var(--black);
}

div.light-green {
    background-color: var(--light-green);
}

div.green {
    background-color: var(--green);

    color: var(--offwhite);
}

div.green.inverted {
    background-color: var(--offwhite);
    border: 2.5rem solid var(--green);

    color: var(--green);
}

div.frosted-white {
  background-color: #ffffff66;
  backdrop-filter: blur(5rem);
}

.border-primary {
    border: 2rem solid var(--primary);
}

/*TABS*/

.left-under-tab {
    --r: 24rem;

    border: solid #0000;
    border-width: 0 var(--r);
    border-radius: 0 0 0 calc(2*var(--r))/var(--r);
    mask:
        radial-gradient(var(--r) at var(--r) 100%, #0000 98%, #000 101%) calc(-1*var(--r)) 0/100% var(--r) repeat-x,
        conic-gradient(#000 0 0) padding-box;
    border-right-width: 0;
    border-top-right-radius: 0;
}

.right-under-tab {
    --r: 24rem;

    border: solid #0000;
    border-width: 0 var(--r);
    border-radius: 0 0 calc(2*var(--r)) 0/var(--r);
    mask:
    radial-gradient(var(--r) at var(--r) 100%, #0000 98%, #000 101%) calc(-1*var(--r)) 0/100% var(--r) repeat-x,
        conic-gradient(#000 0 0) padding-box;
    border-left-width: 0;
    border-top-left-radius: 0;
}

.under-tab {
    --r: 24rem;

    border: solid #0000;
    border-width: 0 var(--r);
    border-radius: 0 0 calc(2*var(--r)) calc(2*var(--r))/var(--r);
    mask:
        radial-gradient(var(--r) at var(--r) 100%, #0000 98%, #000 101%) calc(-1*var(--r)) 0/100% var(--r) repeat-x,
        conic-gradient(#000 0 0) padding-box;
}

/*PRESETS*/

/*SELECTOR*/

.circle-6,
.heart-6 {
    width: 101rem !important;
    height: 101rem !important;
}

.circle-8 {
    width: 117rem !important;
    height: 117rem !important;
}

.circle-9,
.heart-9 {
    width: 125rem !important;
    height: 125rem !important;
}

.heart-8 {
    position: relative;
    width: 74rem !important;
    height: 74rem !important;
    margin: 24rem 30rem 10rem 24rem;
    transform: rotate(-45deg);
    border-radius: calc(74rem / 50) !important;
    border: 3.5rem solid var(--light-brown);
    border-top-color: transparent !important;
    border-right-color: transparent !important;
}

.heart-8 p {
    transform: rotate(45deg) translate(1.5rem, -10rem);
}

.heart-8::before,
.heart-8::after {
    transition: all .2s;
    position: absolute;
    content: '';
    width: 74rem;
    height: 74rem;
    border-radius: 50%;
    border: 3rem solid var(--light-brown);
    border-top-color: transparent;
    border-right-color: transparent;
    background-color: transparent;
}

.heart-8::before {
    top: calc(-74rem / 2 - 2.5rem);
    left: -2.5rem;
    transform: rotate(135deg);
}

.heart-8::after {
    top: -3rem;
    left: calc(68rem / 2 + 2rem);
    transform: rotate(-135deg);
}

.heart-9 {
    position: relative;
    width: 85rem !important;
    height: 85rem !important;
    margin: 24rem 30rem 10rem 24rem;
    transform: rotate(-45deg);
    border-radius: calc(85rem / 50) !important;
    border: 3.5rem solid var(--light-brown);
    border-top-color: transparent !important;
    border-right-color: transparent !important;
}

.heart-9 p {
    transform: rotate(45deg) translate(2rem, -11rem);
}

.heart-9::before,
.heart-9::after {
    transition: all .2s;
    position: absolute;
    content: '';
    width: 85rem;
    height: 85rem;
    border-radius: 50%;
    border: 3rem solid var(--light-brown);
    border-top-color: transparent;
    border-right-color: transparent;
    background-color: transparent;
}

.heart-9::before {
    top: calc(-85rem / 2 - 2.5rem);
    left: -2.5rem;
    transform: rotate(135deg);
}

.heart-9::after {
    top: -3rem;
    left: calc(77.5rem / 2 + 2rem);
    transform: rotate(-135deg);
}

.heart-8:hover,
.heart-9:hover {
    border-top-color: transparent;
    border-right-color: transparent;
}

.heart-8:hover::after,
.heart-8:hover::before,
.heart-9:hover::after,
.heart-9:hover::before,
.heart-8.selected::after,
.heart-8.selected::before,
.heart-9.selected::after,
.heart-9.selected::before {
    transition: all .2s;
    border-color: var(--primary);
    border-top-color: transparent;
    border-right-color: transparent;
}