:root {
  --font-size: .058vw;
  font-size: var(--font-size);
  --primary: #FF5271;
  --primary-50: #ff527289;
  --primary-30: #FF52714D;
  --primary-light: #F9DBE0;
  --purple: #E5CEFAFF;
  --dark-purple: #8B76AB;
  --darker-purple: #5C4A78;
  --white: #FDEFF0;
  --offwhite: #FBF8F6;
  --light-brown: #DCD2CC;
  --black: #2E241C;
  --light-black: #3A3A3A;
  --grey: #454545;
  --bolded-grey: #4F4F4F;
  --light-grey: #717173;
  --lighter-grey: #979797;
  --faded-grey: #AFAFAF;
  --lightest-grey: #D0D0D0;
  --semi-white: #C5C5C5;
  --light-green: #D0F7D0;
  --green: #129930;
  --half-primary: #c1828e;
  --ube: #FFB8DE;
  --strawberry: #FFB8CA;
  --chocolate: #FFDAB8;
  --red-velvet: #FFB8B8;
  --pandan: #D7FFB8;
  --pink: #FF9CA4;
  --fontSizeBase: 24rem;
}

html {
  font-size: var(--root-font);
  background-color: var(--primary);
  color: var(--black);
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

body {
  position: relative;
}

p {
  margin: 0;
}

img {
  object-fit: cover;
}

input, .input {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
}

.App {
  min-height: 100vh;
}

.layout {
  align-self: stretch;
  flex: 1 0 0;
}

.row-divider-1,
.column-divider-1,
.row-divider-2,
.column-divider-2 {
  display: flex;
  align-self: stretch;
  background-color: var(--light-grey);
}

.row-divider-1.invisible,
.column-divider-1.invisible,
.row-divider-2.invisible,
.column-divider-2.invisible {
  background-color: #0000;
}

.row-divider-1 {
  height: 1rem;
}

.column-divider-1 {
  width: 1rem;
}

.row-divider-2 {
  height: 2rem;
}

.column-divider-2 {
  width: 2rem;
}

.z-index-1 {
  z-index: -1;
}

[contenteditable] {
  outline: none;
}

.redirect, a {
  text-decoration: none;
  color: var(--black);
}

.popup {
  z-index: 900;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #ffdde190;
  backdrop-filter: blur(5rem);
  overflow: scroll;
}

/*MOUSE INTERACTIONS*/

.hover-none:hover {
  cursor: default;
}

.hover-pointer:hover {
  cursor: pointer;
}

.content-editable:hover {
  cursor: text;
}

.hover-text:hover {
  cursor: text;
}

.hover-visible {
  display: none;
}

.hover-visible:hover {
  display: block;
}

/* div:has(> .hover-appear) {
  position: relative;
} */

.hover-appear {
  /* display: none; */
  /* position: absolute; */
  /* left: 100%; */
  width: auto;
  width: 0;
  transition: width .4s;
}

div:hover + .hover-appear {
  width: 36rem;
}

.hover-appear p {
  display: none;
  max-width: 0%;
}

div:hover + .hover-appear p {
  display: flex;
  max-width: 100%;
}

/*SPECIAL CASES*/

.list-item {
  padding-left: 2rem;
}

input {
  background-color: #0000;
  border: none;
  padding: 0;
}

.reference-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10rem;
  bottom: 2rem;
  left: 2rem;
}

.photo-box {
  width: 300rem;
  height: 300rem;
}

.reference-label {
  border: #0000;
  width: 100%;
  height: 100%;
  font-size: 3rem;
  line-height: calc(14.5rem/2 - 4px);
  position: relative;
}

.reference-photo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.confirm-background {
  position: fixed;
  z-index: 100;
  width: 100vw !important;
  height: 100vh !important;
  top: 0;
  left: 0;
  background-color: #ffdde190;
  backdrop-filter: blur(5rem);
  -webkit-backface-visibility: hidden;
  /* overflow: scroll; */
}

.confirm-container {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 101;
  background-color: white;
  transform: translateX(-50%) translateY(-50%);
  padding: 32rem;
  max-width: 80vw;
}

.item-image {
  object-fit: contain;
}

/*COLORS*/

.primary-gradient {
  background-image: linear-gradient(var(--primary), var(--primary-50), #0000);
}

/*TRANSITIONS*/

.drawer-children {
  overflow: scroll;
}

.drawer.close .drawer-children {
  max-height: 0;
  transition: all .35s ease-in;
}

.drawer.open .drawer-children {
  max-height: 500rem;
  transition: all 1s ease-in-out;
}

.drawer.close {
  gap: 0rem;
  transition: all .5s ease-in-out;
  transition-delay: .175s;
}

.drawer.open {
  gap: 16rem;
  transition: all .175s ease-in;
}

/*STEP SLIDER*/

.step-slider {
  overflow: hidden;
}

.slider-step {
  /* transition: transform 0.35s ease-in-out; */
  transition: transform 10s ease-in-out;
}

/* Animations */

@keyframes shift-left {
  100% {
    transform: translateX(-30%);
  }
}

@keyframes shift-right {
  0% {
    transform: translateX(-30%);
  }

  100% {
    transform: translateX(0);
  }
}