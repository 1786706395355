button {
    font-family: "DM Sans", sans-serif;
    font-size: 28rem;
    font-weight: 600;
    width: 100%;
    margin-top: 16rem;
    background-color: var(--primary);
    border-radius: 12rem;
    color: white;
    border: 2rem solid var(--primary);
    padding: 18rem 36rem;
    cursor: pointer;
}